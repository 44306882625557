import Axios from "axios";

export default async function sendOrder(infoCard) {
    let answer 
    await Axios.post(process.env.base_url + "/order", infoCard, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then(function (response) {
            answer = response.data
            // redirectToPay(JSON.stringify(response.data.url));
        })
        .catch(function (error) {
            answer = error
            // console.log(error);
        });
    return answer
}