<template>
    <div class="loading-container" ref="loading">
      <div class="spinner" />
      <p class="loading-message">Пожалуйста подождите проверку операции</p>
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.spinner {
  border-radius: 50%;
  width: 86px;
  height: 86px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #09f;
  animation: spin 1s infinite linear;
  margin: auto;
  margin-bottom: 15px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>