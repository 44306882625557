<template>
  <header class="s-header">
    <div class="container">
        <div class="s-header__wrapper s-header__top">
            <div class="s-header__burger"></div>
            <a href="https://prostor.tech/" class="s-header__wrapper-logo">ПРОСТОР</a>
            <div class="s-header__wrapper-right">
                <div v-for="contact in contacts" :key="contact.link" class="s-header__right-contact">
                    <a class="s-header__right-logo" :href="contact.link">
                        <img :src="'/images/header/'+contact.logo" :alt="contact.logo">
                        {{ contact.label }}
                    </a>
                </div>
            </div>
        </div>
        <div class="s-header__wrapper s-header__bottom">
            <div v-for="link in links" :key="link.title" class="s-header__wrapper-nav">
                <a :href="link.link" class="s-header__nav-link">{{ link.label }}</a>
            </div>
        </div>
    </div>
  </header>
</template>

<script setup>

const contacts=[
    {
        link:'mailto:info@arps.pro',
        logo:`mail.svg`,
        label:'info@arps.pro'
    },
    {
        link:'',
        logo:`map.svg`,
        label:'г. Ростов-на-Дону, ул. Социалистическая, 37'
    },
    {
        link:'tel:8 863 285 0900',
        logo:`tel.svg`,
        label:'8 863 285 0900'
    },
];
const links =[
    {
        link:'https://prostor.tech/#how-pay',
        label:'Как оплатить проезд?'
    },    {
        link:'https://prostor.tech/#up-account',
        label:'Как пополнить?'
    },    {
        link:'https://prostor.tech/oblast/',
        label:'Ростовская область'
    },    {
        link:'https://prostor.tech/bankovskaya-karta-i-smartfon/',
        label:'Стоп-лист'
    },    {
        link:'https://prostor.tech/#faq-section',
        label:'Вопрос-ответ'
    },    {
        link:'https://prostor.tech/#news-section',
        label:'Новости'
    },    {
        link:'https://prostor.tech/kontakty/',
        label:'Контакты'
    }
]
</script>

<style lang="scss">
@import './s-header.scss';
</style>