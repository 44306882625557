import Axios from "axios";

export default async function getPrintCard(numberCard) {
    let PrintCard
    await Axios.get(process.env.base_url + "/cards/print/" + numberCard, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then(function (response) {
        PrintCard = response.data
    }).catch(function (error) {
        PrintCard = error
        return error
    });
    return PrintCard
}
