export default function actualMonth() {
    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    const date = new Date();
    const dayOfMonth = String(date.getDate());
    let monthIndex = date.getMonth();
    if (Number(dayOfMonth) >= Number(process.env.pay_day)) {
        monthIndex = (monthIndex + 1) % 12;
    }

    const month = months[monthIndex];
    return month
}