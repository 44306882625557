<template>
    <div class="m-done">
        <div class="m-done__wrapper">
            <div class="m-done__logo">
                <img src="/images/done/done.svg" alt="done">
            </div>
            <h2 class="m-done__title">
                Продление карты прошло успешно
            </h2>
            <div class="m-done__prefix">
                Срок действия карты успешно продлен
            </div>
            <router-link class="m-done__button" to="/">На главную</router-link>
        </div>
    </div>
</template>

<script setup></script>
<style lang="scss">
    @import './m-done.scss';
</style>