
<template>
  <div class="s-main">
    <div class="s-main__wrapper">
      <div class="s-main__wrapper-image">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div v-for="card,i in arrcard" :key="i" :class="[i==0 ? 'carousel-item active': 'carousel-item']">
              <img lazy :src="`/images/${card}.png`" :alt="card">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="s-main__wrapper-notification">
        <a-notification :label="notificationText"/>
      </div>
      <div class="s-main__wrapper-form">
        <form class="s-main__form" @submit.prevent="approveForm(giveInfo)">
          <div class="s-main__form-input">
            <input
              type="text"
              placeholder="Введите номер карты для продления"
              required
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57 && /^\d{0,8}$/.test(this.value));"
              v-model="card.input"
            />
          </div>
          <div class="s-main__form-input">
            <label for="askEmail" class="s-main__form-checkbox">
              <input
                type="checkbox"
                name="email"
                id="askEmail"
                v-model="mail.approve"
                @change="mail.bug.alert = false, clearMail()"
              />
              <p>Мне нужен чек на EMAIL (НЕОБЯЗАТЕЛЬНО)</p>
            </label>
            <input v-if="mail.approve"
              type="text"
              placeholder="Email (НЕОБЯЗАТЕЛЬНО)"
              v-model="mail.input"
            />
          </div>
          <div class="s-main__form-button">
            <button type="submit" :disabled="card.bug.alert || mail.bug.alert">
              {{ btnsubmit }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="s-main__alerts">
      <a-alerts v-if="card.bug.alert" class="s-main__alerts-error" :label="card.bug.message" />
      <a-alerts v-if="mail.bug.alert" class="s-main__alerts-error" :label="mail.bug.message" />
    </div>
  </div>
</template>

<script setup>
import ANotification from "@/components/a-notification/a-notification.vue"
import AAlerts from "@/components/a-alerts/a-alerts.vue"
import { ref,computed,watch } from "vue";

import getPrintCard from '@/api/getPrintCard'
import sendOrder from '@/api/sendOrder'
import actualMonth from '@/utils/sortMonth';

const regular = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
const giveInfo = {
  cardUid: "",
  backUrlPattern: process.env.back_url_pattern,
  email: "",
  amount: 100,
  balance: 0,
  bankCard: {
    cardHolderLogin: "",
    CardUID: "",
    cardHolderName: "",
  },
};
const arrcard = ['card1','card2']

const btnsubmit = ref('Продлить карту')
const card = ref({
  approve: false,
  input: '',
  bug: {
    alert:false,
    message:''
  }
})

watch(()=> card.value.input, async (newValue) => {
  btnsubmit.value = 'Продлить карту'
  card.value.bug.alert = false
  card.value.bug.message = ''
  giveInfo.cardUid = ''
  if (newValue.length == 9){
    await getPrintCard(card.value.input.replace(" ", ""))
    .then((PrintCard) => {
      if ('replenishableReason' in PrintCard) {
         card.value.bug.alert = true;
        if (PrintCard.replenishableReason == 'карта уже продлена на следующий период') {
          card.value.bug.message = PrintCard.replenishableReason +`</br> с ${PrintCard.activeFrom} по ${PrintCard.activeTo}` 
        } else if (PrintCard.replenishableReason == "карта заблокирована по причине 'утеря'" ) {
          card.value.bug.message = PrintCard.replenishableReason
        }
        card.value.bug.alert = true;
        return
      }
      if (PrintCard.isNFC == false && PrintCard.isReplenishable == true) {
        giveInfo.cardUid = PrintCard.sn;
        btnsubmit.value = `Продлить карту (${PrintCard.amount /100} р.)`
        return
      } else if (
        PrintCard.cardType.includes("Федеральный льготник") == false
      ) {
        card.value.bug.alert = true
        card.value.bug.message = 'Неверный тип карты'
        giveInfo.cardUid = ''
        return;
      } else {
        card.value.bug.alert = true
        giveInfo.cardUid = ''
        if (currentMonth.isCurrentMonth) {
          card.value.bug.message = `Карту нельзя продлить на текущий месяц (${actualMonth()})`;
        } else {
          card.value.bug.message = `Продление на следующий месяц (${actualMonth()}) </br>будет доступно с 21 числа текущего месяца`
        } 
        return;
      }
    }).catch((error)=>{
      console.log(error);
        card.value.bug.alert = true
        card.value.bug.message = 'Проверьте правильность ввода номера карты'
        giveInfo.cardUid = ''
        return
      }
    )
  }
})

const mail = ref({
  approve: false,
  input: '',
  bug: {
    alert:false,
    message:''
  }
})

function clearMail() {
  mail.value.bug.alert = false
  if (mail.value.approve == true) {
    mail.value.input = ''
    mail.value.bug.alert = false
  }
}

watch(() => mail.value.approve,() => {
  giveInfo.email = ''
  mail.value.bug.alert = false
})
watch(() => mail.value.input,() =>{
  mail.value.bug.alert = false
  mail.value.bug.message = ''
})

class CurrentMonth {
  constructor() {
    this.payDay = Number(process.env.pay_day);
    this.isCurrentMonth = new Date().getDate() < this.payDay;
    this.formattedCurrentMonth = this.isCurrentMonth ? actualMonth() : "";
    this.formattedNextMonth = this.isCurrentMonth ? actualMonth(+1) : actualMonth()
  }
}

const currentMonth = new CurrentMonth();
const notificationText = computed(() => {
  if (currentMonth.isCurrentMonth) {
    return `Карта будет продлена на текущий месяц (${currentMonth.formattedCurrentMonth}),</br> 
    пополнение на следующий месяц будет доступно c ${currentMonth.payDay}&nbsp;числа текущего месяца`;
  } else {
    return `Карта будет продлена на следующий месяц (${currentMonth.formattedNextMonth})`;
  }
});

async function approveForm(infoOfCard) {  
  let valid = regular.test(mail.value.input)
  if (card.value.input.length < 9) {
    card.value.bug.alert = true
    card.value.bug.message = 'Номер карты должен содержать 9 цифр'
    return
  } else if(mail.value.approve == true && mail.value.input.length == 0){
    mail.value.bug.alert = true
    mail.value.bug.message = 'Пустое значение поля почты'
    return
  } else if (mail.value.approve == true && !valid) {
    mail.value.bug.alert = true
    mail.value.bug.message = 'Неверный формат почты'
    return
  } else if (card.value.input.length == 9){
      await getPrintCard(card.value.input.replace(" ", ""))
      .then((PrintCard) => {
      if ('replenishableReason' in PrintCard) {
         card.value.bug.alert = true;
        if (PrintCard.replenishableReason == 'карта уже продлена на следующий период') {
          card.value.bug.message = PrintCard.replenishableReason +`</br> с ${PrintCard.activeFrom} по ${PrintCard.activeTo}` 
        } else if (PrintCard.replenishableReason == "карта заблокирована по причине 'утеря'" ) {
          card.value.bug.message = PrintCard.replenishableReason
        }
        card.value.bug.alert = true;
        return
      }
        if (PrintCard.isNFC == false && PrintCard.isReplenishable == true) {
          infoOfCard.cardUid = PrintCard.sn;
          infoOfCard.email = mail.value.input;
          btnsubmit.value = `Продлить карту (${PrintCard.amount /100} р.)`
          sendForm(infoOfCard)
          return
        } else if (
          PrintCard.cardType.includes("Федеральный льготник") == false
        ) {
          card.value.bug.alert = true
          card.value.bug.message = 'Неверный тип карты'
          infoOfCard.cardUid = ''
          return;
        } else {
          card.value.bug.alert = true
          infoOfCard.cardUid = ''
          if (currentMonth.isCurrentMonth) {
            card.value.bug.message = `Карту нельзя продлить на текущий месяц (${actualMonth()})`;
          } else {
            card.value.bug.message = `Продление на следующий месяц (${actualMonth()}) </br>будет доступно с 21 числа текущего месяца`
          } 
          return;
        }
      }
    ).catch((error)=>{
      console.log(error);
        card.value.bug.alert = true
        card.value.bug.message = 'Проверьте правильность ввода номера карты'
        infoOfCard.cardUid = ''
        return
      }
    )
  }
}

async function sendForm(infoOfCard){
  let data = JSON.stringify(infoOfCard);
  await sendOrder(data)
  .then(answer => {

    redirectToPay(answer.url)
  })
}

function redirectToPay(url) {
  if (process.env.pay_new_tab == 'true') {
    window.open(url.replace(/['"]+/g, ""), '_blank')
    return
  } else {
    window.location.replace(url.replace(/['"]+/g, ""))
    return
  }
}
</script>

<style lang="scss">
@import "./s-main.scss";
</style>
