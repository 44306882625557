<template>
  <div class="a-alerts alert alert-dismissible alert-danger" role="alert">
    <div class="a-notification__container">
      <div class="a-notification__container-logo">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.239 18.451L13.442 3.816C13.135 3.305 12.596 3 12 3C11.404 3 10.865 3.305 10.559 3.815L1.76101 18.451C1.44901 18.97 1.44101 19.619 1.73901 20.146C2.03601 20.673 2.59701 21 3.20301 21H20.798C21.403 21 21.965 20.673 22.262 20.146C22.56 19.619 22.551 18.97 22.239 18.451ZM13 18H11V16H13V18ZM13 14H11V9H13V14Z" fill="#FC0000"/>
        </svg>
      </div>
      <div class="a-alerts__text" v-html="capitalize(label)"></div>
    </div>

  </div>
</template>

<script setup>
import { defineProps } from "vue";
defineProps({
  label: String,
});

function capitalize(el) {
  el = el.toLowerCase()
  el = el.charAt(0).toUpperCase() + el.slice(1)
  return el
}
</script>

<style lang="scss">
@import "./a-alerts.scss";
</style>