<template>
  <s-header />
  <main class="container">
    <a-loading v-if="!accepted" />
    <m-done v-if="accepted" />
  </main>
</template>

<script setup>
import MDone from "@/components/m-done/m-done.vue";
import ALoading from "@/components/a-loading/a-loading.vue";
import SHeader from "@/components/s-header/s-header.vue";
import { onMounted, ref } from "vue";
import axios from "axios";

const accepted = ref(false);
const getTransId = window.location.search.replace("?id=", "");
function getApprove() {
  axios
    .get(process.env.base_url + "/order/" + getTransId, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(async function (response) {
      if (response.data.status == "APPROVED") {
        accepted.value = true;
        return;
      } else {
        setTimeout(getApprove, 10000);
      }
    })
    .catch(function () {
      setTimeout(getApprove, 10000);
    });
}
onMounted(() => {
  getApprove();
});
</script>